import { TypographyV2 } from "@components/index";
import { Carousel } from "antd";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./_ResearchTeam.module.scss";

export default function ResearchTeam() {
	return (
		<div className={styles.researchTeamContainer}>
			<TypographyV2
				variant="HEADING_4"
				weight="MEDIUM"
				color="text-primary"
				className={styles.researchTeamSectionHeader}
			>
				Streamlining research team productivity
			</TypographyV2>
			<div className={styles.list}>
				{ListedResearches.map((item) => (
					<ResearchTeamCard
						title={item.title}
						subTitle={item.subTiitle}
						Image={item.image}
						imageClassName={item.imageClassName}
						cardClassName={item.cardClassName}
					/>
				))}
			</div>
			<div className={styles.reasearchTeamCarouselContainer}>
				<div style={{ width: "101%", overflowX:'clip' }}>
					<Carousel className="researchTeamCarousel" dots>
						{ListedResearches &&
							ListedResearches.map((item, index) => (
								<ResearchTeamCard
									title={item.title}
									subTitle={item.subTiitle}
									Image={item.image}
									imageClassName={item.imageClassName}
									cardClassName={item.cardClassName}
									key={index}
								/>
							))}
					</Carousel>
				</div>
			</div>
		</div>
	);
}

type ResearchTeamCard = {
	title: string;
	subTitle: string;
	Image: () => JSX.Element;
	imageClassName?: string;
	cardClassName?: string;
};
function ResearchTeamCard({
	title,
	subTitle,
	Image,
	imageClassName = "",
	cardClassName,
}: ResearchTeamCard) {
	return (
		<div
			className={classNames(
				styles.researchTeamCard,
				styles[cardClassName]
			)}
		>
			<div
				className={classNames(
					styles.responsiveImage,
					styles[imageClassName]
				)}
			>
				<Image />
			</div>
			<div className={styles.researchCardTextContentContainer}>
				<TypographyV2
					variant="HEADING_4"
					weight="MEDIUM"
					color="brand-500"
					className={styles.rcTitle}
				>
					{title}
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					weight="REGULAR"
					color="text-tertiary"
					className={styles.rcSubTitle}
				>
					{subTitle}
				</TypographyV2>
			</div>
		</div>
	);
}

const ConsumablesImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/consumables.png"
		alt="pink squares 0"
	/>
);
const NotebookImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/notebook.png"
		alt="pink squares 0"
	/>
);
const ReportAndProtocolsImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/reportsnprotocols.png"
		alt="pink squares 0"
	/>
);
const EquipmentsImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/equipments.png"
		alt="pink squares 0"
	/>
);
const FreezerImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/visualize.png"
		alt="pink squares 0"
	/>
);

const ListedResearches: {
	image: () => JSX.Element;
	title: string;
	subTiitle: string;
	imageClassName?: any;
	cardClassName?: any;
}[] = [
	{
		title: "Manage consumables",
		subTiitle:
			"Effortlessly track lab consumables with Genemod LIMS. Visualize space layouts for precise inventory management.",
		image: ConsumablesImage,
		imageClassName: "responsiveImageCardA",
		cardClassName: "cardA",
	},
	{
		title: "Visualize sample storage",
		subTiitle:
			"Digitalize freezers and track samples, minimizing manual logging time. Genemod’s Laboratory Information Management System (LIMS) ensures an optimized research workflow.",
		image: FreezerImage,
		imageClassName: "responsiveImageCardB",
		cardClassName: "cardB",
	},

	{
		title: "Generate reports and protocols",
		subTiitle:
			"Create and manage research reports and protocols in a central hub. Genemod’s Electronic Lab Notebook (ELN) offers a user-friendly digital lab workspace.",
		image: ReportAndProtocolsImage,
		imageClassName: "responsiveImageCardC",
		cardClassName: "cardC",
	},
	{
		title: "Simplify equipment scheduling",
		subTiitle:
			"Optimize equipment use with Genemod’s scheduling tool, enabling efficient planning and management in a user-friendly format.",
		image: EquipmentsImage,
		imageClassName: "responsiveImageCardD",
		cardClassName: "cardD",
	},
	{
		title: "Perform real-time collaboration",
		subTiitle:
			"Enhance lab processes with real-time collaboration using Genemod LIMS. Track changes instantly on a unified platform.",
		image: NotebookImage,
		imageClassName: "responsiveImageCardE",
		cardClassName: "cardE",
	},
];
