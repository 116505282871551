// extracted by mini-css-extract-plugin
export var blogSection = "index-module--blogSection--ULkRi";
export var buttons = "index-module--buttons--ndxw+";
export var description = "index-module--description--3Gg0J";
export var digitizeWithConfidence = "index-module--digitizeWithConfidence--i1mU2";
export var hero = "index-module--hero--igj2E";
export var heroVideo = "index-module--heroVideo--KXUBo";
export var institutionCard = "index-module--institutionCard--7+GNb";
export var joinInstitutionBodyClass = "index-module--joinInstitutionBodyClass--c+6Rv";
export var joinInstitutionContainerClass = "index-module--joinInstitutionContainerClass--Xz530";
export var joinInstitutionsContainer = "index-module--joinInstitutionsContainer--Nxoc4";
export var joinTrustedInstitutions = "index-module--joinTrustedInstitutions--0ouKl";
export var leftBackgroundShape = "index-module--leftBackgroundShape--f51Xe";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--2fQp6";
export var mask = "index-module--mask--MOZVm";
export var moveCardDesktop = "index-module--moveCard-desktop--YQvor";
export var moveCardTablet = "index-module--moveCard-tablet--NiSPx";
export var newsSectionHeader = "index-module--newsSectionHeader--jS6rE";
export var outerContainerClassName = "index-module--outerContainerClassName---vSPl";
export var partnerCard = "index-module--partnerCard--+HC-x";
export var partnersWrapper = "index-module--partnersWrapper--xt8aA";
export var rightBackgroundShape = "index-module--rightBackgroundShape--RnrqG";
export var title = "index-module--title--Vu6RA";
export var videoContainer = "index-module--videoContainer--UPIvv";
export var videoMask = "index-module--videoMask--KKfgy";
export var videoWrapper = "index-module--videoWrapper--gMJE4";