// extracted by mini-css-extract-plugin
export var cardA = "_ResearchTeam-module--cardA--z5LoM";
export var cardB = "_ResearchTeam-module--cardB--oeyG-";
export var cardC = "_ResearchTeam-module--cardC--BSVqK";
export var cardD = "_ResearchTeam-module--cardD--Q3Kzg";
export var cardE = "_ResearchTeam-module--cardE--5CqyL";
export var limitWidthOnLargeScreens = "_ResearchTeam-module--limitWidthOnLargeScreens--vSqjg";
export var list = "_ResearchTeam-module--list--BL6nA";
export var rcSubTitle = "_ResearchTeam-module--rcSubTitle--xdKWx";
export var rcTitle = "_ResearchTeam-module--rcTitle--24UbG";
export var reasearchTeamCarouselContainer = "_ResearchTeam-module--reasearchTeamCarouselContainer--b6xk5";
export var researchCardTextContentContainer = "_ResearchTeam-module--researchCardTextContentContainer--4lX3r";
export var researchTeamCard = "_ResearchTeam-module--researchTeamCard--w-LnQ";
export var researchTeamContainer = "_ResearchTeam-module--researchTeamContainer--sCtqe";
export var researchTeamSectionHeader = "_ResearchTeam-module--researchTeamSectionHeader--uXqIx";
export var responsiveImage = "_ResearchTeam-module--responsiveImage--iKdDe";
export var responsiveImageCardA = "_ResearchTeam-module--responsiveImageCardA--kD+Ef";
export var responsiveImageCardB = "_ResearchTeam-module--responsiveImageCardB--fObvi";
export var responsiveImageCardC = "_ResearchTeam-module--responsiveImageCardC--Hbs5a";
export var responsiveImageCardD = "_ResearchTeam-module--responsiveImageCardD--ktK-x";
export var responsiveImageCardE = "_ResearchTeam-module--responsiveImageCardE--8TkHY";