// extracted by mini-css-extract-plugin
export var bodyText = "_HeroV2-module--bodyText--Hg-So";
export var buttons = "_HeroV2-module--buttons--CQ72A";
export var headerText = "_HeroV2-module--headerText--4sqlK";
export var hero = "_HeroV2-module--hero--+cMpC";
export var heroContainer = "_HeroV2-module--heroContainer--F-6BK";
export var heroVideo = "_HeroV2-module--heroVideo--3LLsb";
export var limitWidthOnLargeScreens = "_HeroV2-module--limitWidthOnLargeScreens--FlbTk";
export var textButtonsContainer = "_HeroV2-module--textButtonsContainer--LYKFm";
export var videoContainer = "_HeroV2-module--videoContainer--Vr6WS";
export var videoMask = "_HeroV2-module--videoMask--+JZuK";
export var videoWrapper = "_HeroV2-module--videoWrapper--q1NnC";