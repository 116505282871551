// extracted by mini-css-extract-plugin
export var badge = "_Industries-module--badge--UCtO9";
export var badgeAndLearnMoreSection = "_Industries-module--badgeAndLearnMoreSection--5iCF2";
export var badges = "_Industries-module--badges--P5+Sp";
export var content = "_Industries-module--content--atb2+";
export var contentImageContainer = "_Industries-module--contentImageContainer--NSOMh";
export var firstImage = "_Industries-module--firstImage--i-WHn";
export var imagesCard = "_Industries-module--imagesCard--FYZZX";
export var imagesContainer = "_Industries-module--imagesContainer--gqphF";
export var industries = "_Industries-module--industries--tPBrD";
export var industriesContainer = "_Industries-module--industriesContainer--QJXhT";
export var learnMoreButton = "_Industries-module--learnMoreButton--CsUt5";
export var learnMoreButtonContainer = "_Industries-module--learnMoreButtonContainer--XAxwR";
export var learnMoreButtonText = "_Industries-module--learnMoreButtonText--3G0+Q";
export var limitWidthOnLargeScreens = "_Industries-module--limitWidthOnLargeScreens--jMTju";
export var orangeBorder = "_Industries-module--orangeBorder--T07Db";
export var secondImage = "_Industries-module--secondImage--vSv1d";
export var tabAndContent = "_Industries-module--tabAndContent--i+Qki";
export var tabElements = "_Industries-module--tabElements--e83Zo";
export var tabs = "_Industries-module--tabs--jMdvJ";
export var title = "_Industries-module--title--Zcr8K";
export var titleAndContent = "_Industries-module--titleAndContent--PqGtr";
export var titleAndContentContainer = "_Industries-module--titleAndContentContainer--VttuQ";