// extracted by mini-css-extract-plugin
export var avatar = "index-module--avatar--Cs5at";
export var avatarAuthorDate = "index-module--avatarAuthorDate--uN0E6";
export var blogCards = "index-module--blogCards--wVPRH";
export var blogCarouselContainer = "index-module--blogCarouselContainer--7ekSf";
export var blogRollBackground = "index-module--blogRollBackground--iCanw";
export var cardContainer = "index-module--cardContainer--uoUrD";
export var icon = "index-module--icon--cnbEm";
export var image = "index-module--image--bnKQV";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--2NSNv";
export var readMoreBlogs = "index-module--readMoreBlogs--wMvJT";
export var readMoreButton = "index-module--readMoreButton--MW9Nb";
export var readMoreButtonText = "index-module--readMoreButtonText--x0z9x";
export var summary = "index-module--summary--7Krfo";
export var threeLines = "index-module--threeLines--o3IW-";
export var title = "index-module--title--MmwI5";
export var titleAndIcon = "index-module--titleAndIcon--8qhLH";
export var titleSummary = "index-module--titleSummary--G+Q+n";
export var titleSummaryAvatarAuthorDate = "index-module--titleSummaryAvatarAuthorDate--Xpj1X";