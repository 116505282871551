import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const EquipmentsProductsImage = () => (
	<StaticImage
		loading="eager"
		src="./equipment-product.png"
		alt="Biopharmaceutical"
	/>
);

export const OrdersProductsImage = () => (
	<StaticImage
		loading="eager"
		src="./order collaboration.png"
		alt="Biopharmaceutical"
	/>
);

export const ConsumablesProductsImage = () => (
	<StaticImage
		loading="eager"
		src="./consumables space layout.png"
    alt="Biopharmaceutical"
    style={{
      width: "100%",
    }}
	/>
);


