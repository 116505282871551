// extracted by mini-css-extract-plugin
export var gridCol = "Products-module--gridCol--aIAib";
export var horizontalRule = "Products-module--horizontalRule--CxkGS";
export var industries = "Products-module--industries--DNORv";
export var industriesSectionHeader = "Products-module--industriesSectionHeader--q1mei";
export var item = "Products-module--item--cpNtv";
export var itemText = "Products-module--itemText--lOscg";
export var itemTextstyle = "Products-module--itemTextstyle--ClFgX";
export var learnMore = "Products-module--learnMore--Td6-E";
export var learnMoreButtonContainer = "Products-module--learnMoreButtonContainer--5tqSb";
export var learnMoreButtonText = "Products-module--learnMoreButtonText--gxHNd";
export var limitWidthOnLargeScreens = "Products-module--limitWidthOnLargeScreens--uzre1";
export var list = "Products-module--list--vLPcL";
export var noFade = "Products-module--noFade--NBmJe";
export var productCardContainer = "Products-module--productCardContainer--khsmU";
export var productTextContent = "Products-module--productTextContent--7CCx6";
export var productTitleText = "Products-module--productTitleText--DRAed";
export var rightContainer = "Products-module--rightContainer--2A60I";
export var rightContainerBodyWrapper = "Products-module--rightContainerBodyWrapper--SCAzs";
export var star = "Products-module--star--kXrxK";
export var tabAndContent = "Products-module--tabAndContent--0MBFy";
export var tabElements = "Products-module--tabElements--w48V9";
export var tabs = "Products-module--tabs--ImDrt";
export var tabsContainer = "Products-module--tabsContainer--vyTRL";
export var video = "Products-module--video--It8iD";
export var videoContainer = "Products-module--videoContainer--DS1tn";