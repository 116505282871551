import React from "react";
import * as gridStyles from "./GridCard.module.scss";
import cn from "classnames";
import { useWindowSize } from "@helpers/Hooks";

const GRID_DIM = 20; // Pixels

/**
 * Div with background color that is grey with a grid pattern, white grid lines
 */
export const GridCard = (props: React.HTMLAttributes<HTMLDivElement>) => {
	useWindowSize();
	const ref = React.useRef<HTMLDivElement>(null);

	let backgroundPositionX = -1,
		backgroundPositionY = -1;

	if (ref.current) {
		const { clientWidth, clientHeight } = ref.current;
		backgroundPositionX = (-1 * (clientWidth % GRID_DIM)) / 2;
		backgroundPositionY = (-1 * (clientHeight % GRID_DIM)) / 2;
	}

	return (
		<div
			{...props}
			className={cn(gridStyles.gridCard, props.className)}
			ref={ref}
			style={{
				backgroundPositionX,
				backgroundPositionY,
				...(props.style || {}),
			}}
		></div>
	);
};
