import React, { FunctionComponent } from "react";
import * as styles from "./_Sectionheader.module.scss";
import { GridCol, TypographyV2 } from "@components/index";
import { Blockquote, StarsIconV2SectionHeader } from "@assets/icons";
import "@css/variables";
import classNames from "classnames";

const SectionHeaderInfo: {
	[key: string]: {
		iconText: string;
		headerText: string;
		icon: JSX.Element;
		secondIcon?: JSX.Element;
	};
} = {
	products: {
		iconText: "Products",
		headerText: "Streamlining research team productivity",
		icon: <StarsIconV2SectionHeader />,
		secondIcon: <StarsIconV2SectionHeader />,
	},
	news: {
		iconText: "Latest News",
		headerText: "Stay ahead of the latest trends",
		icon: <Blockquote />,
	},
};

export type SectionHeaderProps = {
	type: "products" | "news";
	className?: string;
	outerContainerClassName?: string;
};

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
	type,
	className,
	outerContainerClassName
}) => {
	return (
		<GridCol className={classNames(styles.outerContainer, outerContainerClassName)}>
			<TypographyV2
				variant={"HEADING_2"}
				tabletVariant="HEADING_3"
				mobileVariant="HEADING_4"
				weight="SEMIBOLD"
				className={classNames(styles.headerText, className)}
			>
				{SectionHeaderInfo[type].headerText}
			</TypographyV2>
		</GridCol>
	);
};
