import AI_MOBILE_VIDEO from "@assets/products/landing/ai-chat-mobile.mp4";
import AI_VIDEO from "@assets/products/landing/ai-chat.mp4";
import NOTEBOOK_MOBILE_VIDEO from "@assets/products/landing/digital-notebook-mobile.mp4";
import NOTEBOOK_VIDEO from "@assets/products/landing/digital-notebook.mp4";
import INVENTORY_MOBILE_VIDEO from "@assets/products/landing/modern-inventory-mobile.mp4";
import INVENTORY_VIDEO from "@assets/products/landing/modern-inventory.mp4";
import { TabsV2, TypographyV2 } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import {
	LearnMoreButtonV3,
} from "@components/Button_new/Button";
import { mapToKeyValue } from "@helpers/Hooks";
import React, { ReactNode, useEffect, useRef } from "react";
import { GridCard } from "../_GridCard/_GridCard";
import * as styles from "./Products.module.scss";


import {
	ConsumablesProductsImage,
	EquipmentsProductsImage,
	OrdersProductsImage,
} from "@assets/products/landing";
import { LinkType } from "@components/Link/Link";
import classNames from "classnames";

type ProductCardBaseProps = {
	features: string[];
	title: string;
	mediaType: "Video" | "Image";
	mobileVideo?: any;
	video?: any;
	poster?: any;
	image?: ReactNode;
};

interface ImageProps extends ProductCardBaseProps {
	mediaType: "Image";
	image: ReactNode;
}
interface VideoProps extends ProductCardBaseProps {
	mediaType: "Video";
	mobileVideo: any;
	video: any;
	poster: any;
}
const TAB_CONTENT: ((ImageProps | VideoProps) & {
	to: LinkType;
	tabTitle: string;
	showBackgroundFade?: boolean;
})[] = [
	{
		tabTitle: "Notebook",
		title: "Electronic lab notebook",
		mobileVideo: NOTEBOOK_MOBILE_VIDEO,
		video: NOTEBOOK_VIDEO,
		poster: "/notebook-placeholder.png",
		to: "NOTEBOOK",
		mediaType: "Video",
		features: [
			"Live document editing within the main text editor saves you time and gives you instant updates.",
			"Collaborate seamlessly with team members in one place, via real-time edits, comments, and feedback; no more confusion over different “versions.”",
			"Draft, revise, and finalize research documents efficiently while retaining full document version history.",
		],
		showBackgroundFade: true,
	},
	{
		tabTitle: "Inventory",
		title: "Inventory management",
		mobileVideo: INVENTORY_MOBILE_VIDEO,
		video: INVENTORY_VIDEO,
		poster: "/boxes-placeholder.png",
		to: "INVENTORY",
		mediaType: "Video",
		features: [
			"Our user-friendly interface makes adding reagents a breeze. Choose from preset configurations or create your own custom item types.",
			"Keep your data safe and secure with export options and boost your inventory tracking with printable barcodes.",
			"Enjoy a system that’s designed to combine maximum user comfort with advanced features.",
		],
		showBackgroundFade: true,
	},
	{
		tabTitle: "Equipment",
		title: "Lab equipment management",
		to: "EQUIPMENT",
		mediaType: "Image",
		image: <EquipmentsProductsImage />,
		features: [
			"Get a complete view of all available instruments to optimize usage and minimize downtown.",
			"Use the visual schedule to quickly see when equipment is booked, making planning easier and reducing overlaps.",
			"Promote effective coordination among team members to optimize instrument usage and keep up a continuous workflow.",
		],
		showBackgroundFade: false,
	},
	{
		tabTitle: "Consumables",
		title: "Lab consumables management",
		to: "CONSUMABLES",
		image: <ConsumablesProductsImage />,
		mediaType: "Image",
		features: [
			"Set up specific sections or categories designed for biopharma research consumables.",
			"Improve your organization by defining precise item groups within the biopharma context.",
			"This refined categorization gives you precise tracking, which is crucial for maintaining the integrity of your biopharma research.",
		],
		showBackgroundFade: true,
	},
	{
		tabTitle: "Orders",
		title: "Order management",
		image: <OrdersProductsImage />,
		to: "ORDERS",
		mediaType: "Image",
		features: [
			"Get a comprehensive view of all order requests and their current status on one dashboard.",
			"Keep things clear with high-level overviews, so you always know where each item stands.",
			"Speed up the order submission process with user-friendly tools, making approvals and processing a breeze.",
		],
		showBackgroundFade: false,
	},
	{
		tabTitle: "Genemod AI",
		title: "Genemod AI",
		mobileVideo: AI_MOBILE_VIDEO,
		video: AI_VIDEO,
		poster: "/ai-placeholder.png",
		to: "GENEMOD_AI",
		mediaType: "Video",
		features: [
			"Obtain immediate, accurate scientific insights using Genemod AI's extensive database.",
			"Blend various research methods with ease, boosting research efficiency and breadth.",
			"Get tailored advice on experimental processes to meet your specific research needs.",
		],
		showBackgroundFade: true,
	},
];

const VideoComponent = ({
	mobileVideo,
	video,
	poster,
}: {
	mobileVideo: string;
	video: string;
	poster?: string;
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		videoRef.current?.load();
	}, [mobileVideo, video]);

	return (
		<video
			ref={videoRef}
			poster={poster}
			muted
			autoPlay
			loop
			webkit-playsInline
			playsInline
			className={styles.video}
		>
			<source src={video} type="video/mp4" />
			<source
				src={mobileVideo}
				type="video/mp4"
				media="max-width:500px"
			/>
		</video>
	);
};

export default VideoComponent;

const PRODUCTS_TABS = TAB_CONTENT.map((tab) => tab.tabTitle);
type ProductsTabs = typeof TAB_CONTENT[number]["tabTitle"];

export const Products = () => {
	const [selectedTab, setSelectedTab] = React.useState<ProductsTabs>(
		TAB_CONTENT[0]["tabTitle"]
	);

	const {
		title,
		to,
		mobileVideo,
		video,
		poster,
		features,
		mediaType,
		image,
		showBackgroundFade,
	} = mapToKeyValue(TAB_CONTENT, "tabTitle")[selectedTab];

	return (
		<BackgroundFadeContainer
			type="white"
			autoMargin={false}
			className={styles.industries}
		>
			<GridCard className={styles.tabAndContent}>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					color="text-primary"
					className={styles.industriesSectionHeader}
				>
					Put all of your work in one place
				</TypographyV2>
				<div className={styles.tabsContainer}>
					<TabsV2
						tabs={PRODUCTS_TABS}
						selectedTab={selectedTab}
						onChange={setSelectedTab}
						wrapperClass={styles.tabs}
						tabsClassName={styles.tabElements}
					/>
				</div>
				<ProductCardNew
					mobileVideo={mobileVideo}
					video={video}
					poster={poster}
					features={features as unknown as string[]}
					title={title}
					mediaType={mediaType}
					image={image}
					showBackgroundFade={showBackgroundFade}
					to={to}
				/>
			</GridCard>
			{/* <LearnMoreButtonV2 to={to} /> */}
		</BackgroundFadeContainer>
	);
};



const CheckMarkPatter = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 6L9 17L4 12"
				stroke="#2C52F7"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

const ProductCardNew = ({
	features,
	image,
	mobileVideo,
	video,
	poster,
	mediaType,
	showBackgroundFade,
	to,
}: (ImageProps | VideoProps) & {
	showBackgroundFade?: boolean;
	to: LinkType;
}) => {
	return (
		<div className={styles.productCardContainer}>
			{/* right */}
			<div className={styles.productTextContent}>
				<div className={styles.list}>
					{features.map((feature, index) => {
						return (
							<div key={index} className={styles.item}>
								<div className={styles.star}>
									<StarIcon />
								</div>
								<div className={styles.itemText}>
									<TypographyV2
										variant="BODY_TEXT_LARGE"
										tabletVariant="BODY_TEXT_LARGE"
										mobileVariant="BODY_TEXT_EXTRA_LARGE"
										color="text-helper"
										weight="MEDIUM"
										className={styles.itemTextstyle}
									>
										{feature}
									</TypographyV2>
								</div>
							</div>
						);
					})}
				</div>
				<div>
					<hr className={styles.horizontalRule} />
					{/* <LearnMoreButtonV2 to={to} className={styles.learnMore} /> */}
					<div className={styles.learnMore}>
						<LearnMoreButtonV3
							to={to}
							textClassName={styles.learnMoreButtonText}
							className={styles.learnMoreButtonContainer}
							showArrow={false}
						/>
					</div>
				</div>
			</div>
			{/* left */}
			<div className={styles.rightContainerBodyWrapper}>
				<div
					className={classNames(
						styles.rightContainer,
						!showBackgroundFade && styles.noFade
					)}
				>
					<div className={styles.videoContainer}>
						{mediaType === "Video" ? (
							<>
								{/* <div className={styles.customBox}></div> */}
								<VideoComponent
									mobileVideo={mobileVideo}
									video={video}
									poster={poster}
								/>
							</>
						) : (
							<>{image}</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

function StarIcon() {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11 1L13.012 6.23109C13.294 6.96432 13.435 7.33093 13.6542 7.63931C13.8486 7.91262 14.0874 8.15141 14.3607 8.34575C14.6691 8.56503 15.0357 8.70603 15.7689 8.98804L21 11L15.7689 13.012C15.0357 13.294 14.6691 13.435 14.3607 13.6542C14.0874 13.8486 13.8486 14.0874 13.6542 14.3607C13.435 14.6691 13.294 15.0357 13.012 15.7689L11 21L8.98804 15.7689C8.70603 15.0357 8.56503 14.6691 8.34575 14.3607C8.15141 14.0874 7.91262 13.8486 7.63931 13.6542C7.33093 13.435 6.96432 13.294 6.23109 13.012L1 11L6.23108 8.98804C6.96431 8.70603 7.33093 8.56503 7.63931 8.34575C7.91262 8.15141 8.15141 7.91262 8.34575 7.63931C8.56503 7.33093 8.70603 6.96431 8.98804 6.23108L11 1Z"
				stroke="#895AF6"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
